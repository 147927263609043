
import { defineComponent, reactive, ref, onBeforeMount, onUnmounted } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import Tips from '@/components/tips/tips.vue'

import {
  secondaryFormTy,
  secondaryVisitableFormTy,
  secondaryFormRulesData,
  CheckDataService
} from './secondary.service'
import { SoftService, channelSoftInfosTY } from '@/services/soft.service'

import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Secondary',
  components: {
    MainLayout,
    Tips,
  },
  setup() {
    const injector = useReflectiveInjector([CheckDataService])
    const service = injector.get(CheckDataService)
    const softService = injector.get(SoftService)

    const dialogFormVisible = ref<boolean>(false)
    // 记录弹窗状态
    const dialogType = ref<string>('add')
    const secondaryForm:secondaryFormTy = reactive({
      name: '',
      password: '',
      softs: [],
      indexs: '',
      id: ''
    })
    /* 业务名称 */
    const channelSoftInfos = ref<channelSoftInfosTY[]>([] as channelSoftInfosTY[])
    /* 订阅业务名称 */
    const subscription = softService.channelSoftInfos.subscribe(list => {
      if (list.length > 0) {
        channelSoftInfos.value = list
        getSecondaryTableInfo()
      }
    })
    onUnmounted(() => {
      // 组件销毁时，取消订阅
      subscription.unsubscribe()
    })
    /* 表单规范 */
    const secondaryFormRules =  secondaryFormRulesData
    const secondaryFormRef = ref()

    /* 添加子渠道 */
    function addSecondary () {
      secondaryFormRef.value.validate((valid: boolean) => {
        if (!valid) return // 校验表单
        service.doAddSecondary(secondaryForm).then((response) => {
          dialogFormVisible.value = false
          getSecondaryTableInfo()
        }).catch((err) => {
          ElMessage.error(`添加数据失败,${err.msg}`)
        })
      })
    }
    /* 重置表单 */
    function resetForm () {
      secondaryFormRef.value.resetFields()
    }
    /* 表格数据 */
    const secondaryTableData = ref<any>([])
    /* 查询 */
    function getSecondaryTableInfo () {
      service.doGetSecondaryTableInfo({page: 1}).then((response) => {
        response?.data.details.forEach(element => {
          const softsNumber = element.softs.split(',')
          const softString: string[] = []
          const softIdNumber: number[] = []
          softsNumber.forEach(sofId => {
            const sofIdNumber = Number(sofId)
            softIdNumber.push(sofIdNumber)
            channelSoftInfos.value.forEach(element => {
              if (element.softid === sofIdNumber) {
                softString.push(element.softname)
              }
            })
          })
          element.softsNumber = softIdNumber
          element.softsString = softString.join(',')
        })
        secondaryTableData.value = response?.data.details || []
        paginationHandle.total = response?.data.page_total || 0
      }).catch((err) => {
        ElMessage.error(`查询数据失败,${err.msg}`)
      })
    }
    /* 修改子用户信息 */
    function modifySecondary (index: number, row: any) {
      secondaryForm.id = row.id
      secondaryForm.password = row.password
      secondaryForm.indexs = row.indexs
      secondaryForm.name = row.username
      secondaryForm.softs = row.softsNumber
      // 记录弹窗状态
      dialogType.value = 'change'
      dialogFormVisible.value = true
    }
    function changeSecondary () {
      secondaryFormRef.value.validate((valid: boolean) => {
        if (!valid) return // 校验表单
        service.doChangeSecondary(secondaryForm).then((response) => {
          dialogFormVisible.value = false
          getSecondaryTableInfo()
        }).catch((err) => {
          ElMessage.error(`修改数据失败,${err.msg}`)
        })
      })
    }
    /* 允许或者禁止操作 */
    function changState (index: number, row: any) {
      const parse: secondaryVisitableFormTy = {
        id: row.id,
        visite: row.enable ? '0' : '1'
      }
      service.doChangeVisitableSecondary(parse).then((response) => {
        getSecondaryTableInfo()
      }).catch((err) => {
        ElMessage.error(`修改数据失败,${err.msg}`)
      })
    }
    const paginationHandle = reactive({
      total: 0,
      page: 1,
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getSecondaryTableInfo()
      }
    })

    return {
      dialogFormVisible,
      dialogType,
      secondaryFormRef,
      secondaryForm,
      secondaryFormRules,
      addSecondary,
      changeSecondary,
      resetForm,
      channelSoftInfos,
      secondaryTableData,
      changState,
      modifySecondary,
      paginationHandle
    };
  },
});
