import { Injectable } from '@tanbo/vue-di-plugin'

import { HttpService } from '@/services/http.service'

export interface secondaryFormTy {
  /** 业务名称 */
  name: string
  /** 子会员登录的密码 */
  password: string
  /** 子会员将要推广的业务 */
  softs: []
  /** 会员获取的渠道ID */
  indexs: string,
  id?: string
}

export interface secondaryChangeFormTy extends secondaryFormTy {
  /* 子渠道ID */
  id: string
}

export interface  secondaryVisitableFormTy {
  /* 子渠道ID */
  id: string
  /* 状态 */
  visite: string
}

export interface secondaryTableliTY {
  username: string
  password: string
  softs: string
  indexs: string
  enable: string
  softsString?: string
  softsNumber?: number[]
}

export interface secondaryTableTY {
  details: secondaryTableliTY[]
  page_total: number
}

export interface secondaryTableRequestData {
  code: number
  data?: secondaryTableTY
  msg: string
}

export interface  secondaryTablePageTy {
  page: number
}

/* 表单规范 */
export const secondaryFormRulesData = {
  name: [
    {
      required: true,
      message: '请填写子会员登录的用户名，如：test_1001',
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      message: '请填写子会员登录的密码，由6-12位英文、数字组成，如：123456',
      trigger: 'blur',
    }
  ],
  softs: [
    {
      type: 'array',
      required: true,
      message: '请勾选子会员将要推广的业务',
      trigger: 'change',
    }
  ],
  indexs: [
    {
      required: true,
      message: '请填写子会员获取的渠道ID',
      trigger: 'blur',
    }
  ]
}

@Injectable()
export class CheckDataService {
  constructor (private http: HttpService) {}

  doAddSecondary (params: secondaryFormTy) {
    return this.http.post<secondaryTableRequestData>('/create_secondary.json', params).then((response) => {
      return response
    })
  }

  doChangeSecondary (params: secondaryFormTy) {
    return this.http.post<secondaryTableRequestData>('/modify_secondary.json', params).then((response) => {
      return response
    })
  }

  doChangeVisitableSecondary (params: secondaryVisitableFormTy) {
    return this.http.post<secondaryTableRequestData>('/modify_secondary_visitable.json', params).then((response) => {
      return response
    })
  }

  doGetSecondaryTableInfo (params: secondaryTablePageTy) {
    return this.http.post<secondaryTableRequestData>('/get_secondarys.json', params).then((response) => {
      return response
    })
  }
}
